import { appExternals } from '@woodmac-lens/app';


import * as TsLib from 'tslib';
import * as React from 'react';
import * as ReactJSXRuntime from 'react/jsx-runtime';
import * as ReactDOM from 'react-dom';
import * as emotionReactJSXRuntime from '@emotion/react/jsx-runtime';
import * as emotionReact from '@emotion/react';
import * as HydrocarbonThemeProvider from '@woodmac-hycb/theme-provider';
import * as HydrocarbonThemeDefault from '@woodmac-hycb/theme-default';
import * as ReactRouter from 'react-router';
import * as ReactRouterDOM from 'react-router-dom';
import * as WoodmacLensApp from '@woodmac-lens/app';
import * as WoodmacLensNavigation from '@woodmac-lens/navigation';

import * as HycbAdvancedTable from '@woodmac-hycb/advanced-table';
import LodashMin from 'lodash/lodash.min';
import Highchart from 'highcharts';
import HighchartHighstock from 'highcharts/highstock';
import HighchartExporting from 'highcharts/modules/exporting';
import HighchartOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartBoost from 'highcharts/modules/boost';
import HighchartBoostCanvas from 'highcharts/modules/boost-canvas';
import HighchartAnnotations from 'highcharts/modules/annotations';
import HighchartSankey from 'highcharts/modules/sankey';
import HighchartDependencyWheel from 'highcharts/modules/dependency-wheel';
import HighchartMore from 'highcharts/highcharts-more';
import HighchartVariWide from 'highcharts/modules/variwide';
import HighchartHeatMap from 'highcharts/modules/heatmap';
import HighchartSunburst from 'highcharts/modules/sunburst';
import type { Container } from 'inversify';


const coreExternals = {
    'tslib': TsLib,
    '@emotion/react': emotionReact,
    '@emotion/react/jsx-runtime': emotionReactJSXRuntime,
    '@woodmac-hycb/theme-default': HydrocarbonThemeDefault,
    '@woodmac-hycb/theme-provider': HydrocarbonThemeProvider,
    '@woodmac-lens/app': WoodmacLensApp,
    '@woodmac-lens/navigation': WoodmacLensNavigation,
    'react': React,
    'react/jsx-runtime': ReactJSXRuntime,
    'react-dom': ReactDOM,
    'react-router': ReactRouter,
    'react-router-dom': ReactRouterDOM,
};

const optionalExternals = {
    '@woodmac-hycb/advanced-table': HycbAdvancedTable,
    'lodash': LodashMin,
    'lodash/lodash.min': LodashMin,
    'highcharts': Highchart,
    'highcharts/highstock': HighchartHighstock,
    'highcharts/modules/exporting': HighchartExporting,
    'highcharts/modules/offline-exporting': HighchartOfflineExporting,
    'highcharts/modules/annotations': HighchartAnnotations,
    'highcharts/modules/boost': HighchartBoost,
    'highcharts/modules/boost-canvas': HighchartBoostCanvas,
    'highcharts/modules/dependency-wheel': HighchartDependencyWheel,
    'highcharts/modules/sankey': HighchartSankey,
    'highcharts/modules/variwide': HighchartVariWide,
    'highcharts/modules/heatmap': HighchartHeatMap,
    'highcharts/modules/sunburst': HighchartSunburst,
    'highcharts/highcharts-more': HighchartMore,
};


const externals = {
    ...coreExternals,
    ...optionalExternals,
};

declare global {
    interface Window {
        container: Container;
        [prop: string]: unknown;
    }
}


const additionalExternals = () => ({
    '@woodmac-hycb/advanced-table': 'HycbAdvancedTable',
    'lodash': 'LodashMin',
    'lodash/lodash.min': 'LodashMin',
    'highcharts': 'Highchart',
    'highcharts/highstock': 'HighchartHighstock',
    'highcharts/modules/annotations': 'HighchartAnnotations',
    'highcharts/modules/boost':  'HighchartBoost',
    'highcharts/modules/boost-canvas': 'HighchartBoostCanvas',
    'highcharts/modules/exporting': 'HighchartExporting',
    'highcharts/modules/offline-exporting': 'HighchartOfflineExporting',
    'highcharts/modules/dependency-wheel': 'HighchartDependencyWheel',
    'highcharts/modules/sankey': 'HighchartSankey',
    'highcharts/modules/variwide': 'HighchartVariWide',
    'highcharts/modules/heatmap': 'HighchartHeatMap',
    'highcharts/modules/sunburst': 'HighchartSunburst',
    'highcharts/highcharts-more': 'HighchartMore',
});

/**
 * Refresh runtime will be handled by separate entry
 */
const externalsProd = Object
    .entries({ ...appExternals(), ...additionalExternals() })
    .filter(([name]) => !name.startsWith('react-refresh'));

externalsProd.forEach(([id, globalPropName]) => {
    window[globalPropName] = externals[id];
});

window.isExternalsLoaded = true;
